import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Footer, IconFacebook, IconTwitter, IconInstagram, IconYoutube, IconTiktok, Link } from 'hds-react';
import ExternalLink from '../../../components/ExternalLink';
import Playground from '../../../components/Playground';
import TabsLayout from './tabs.mdx';
export const _frontmatter = {
  "slug": "/components/footer/code",
  "title": "Footer - Code"
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const IconCheckCircleFill = makeShortcode("IconCheckCircleFill");
const IconCrossCircle = makeShortcode("IconCrossCircle");
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "code",
      "style": {
        "position": "relative"
      }
    }}>{`Code`}<a parentName="h2" {...{
        "href": "#code",
        "aria-label": "code permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "code-examples",
      "style": {
        "position": "relative"
      }
    }}>{`Code examples`}<a parentName="h3" {...{
        "href": "#code-examples",
        "aria-label": "code examples permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <h4 {...{
      "id": "default",
      "style": {
        "position": "relative"
      }
    }}>{`Default`}<a parentName="h4" {...{
        "href": "#default",
        "aria-label": "default permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <Playground mdxType="Playground">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{` <Footer title="Helsinki Design System" footerAriaLabel="HDS Footer">
    <Footer.Navigation navigationAriaLabel="HDS Footer navigation">
        <Footer.Item label="Item" />
        <Footer.Item label="Item" />
        <Footer.Item label="Item" />
        <Footer.Item label="Item" />
        <Footer.Item label="Item" />
        <Footer.Item label="Item" />
        <Footer.Item label="Item" />
        <Footer.Item label="Item" />
    </Footer.Navigation>
    <Footer.Utilities backToTopLabel="Back to top">
        <Footer.SoMe>
          <Footer.Item icon={<IconFacebook />} />
          <Footer.Item icon={<IconTwitter />} />
          <Footer.Item icon={<IconInstagram />} />
          <Footer.Item icon={<IconYoutube />} />
          <Footer.Item icon={<IconTiktok />} />
        </Footer.SoMe>
        <Footer.Item label="Contact us" />
        <Footer.Item label="Give feedback" />
      </Footer.Utilities>
      <Footer.Base copyrightHolder="Copyright" copyrightText="All rights reserved">
        <Footer.Item label="Link" />
        <Footer.Item label="Link" />
        <Footer.Item label="Link" />
        <Footer.Item label="Link" />
        <Footer.Item label="Link" />
      </Footer.Base>
  </Footer>
`}</code></pre>
    </Playground>
    <h3 {...{
      "id": "color-variations",
      "style": {
        "position": "relative"
      }
    }}>{`Color variations`}<a parentName="h3" {...{
        "href": "#color-variations",
        "aria-label": "color variations permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <Playground mdxType="Playground">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`<>
<Footer title="Helsinki Design System" footerAriaLabel="HDS Footer">
    <Footer.Navigation navigationAriaLabel="HDS Footer navigation">
        <Footer.Item label="Item" />
        <Footer.Item label="Item" />
        <Footer.Item label="Item" />
        <Footer.Item label="Item" />
        <Footer.Item label="Item" />
        <Footer.Item label="Item" />
    </Footer.Navigation>
    <Footer.Base copyrightHolder="Copyright" copyrightText="All rights reserved">
      <Footer.Item label="Link" />
      <Footer.Item label="Link" />
      <Footer.Item label="Link" />
    </Footer.Base>
  </Footer>
  <br />
  <Footer title="Helsinki Design System" theme="dark" footerAriaLabel="HDS Footer">
    <Footer.Navigation navigationAriaLabel="HDS Footer navigation">
        <Footer.Item label="Item" />
        <Footer.Item label="Item" />
        <Footer.Item label="Item" />
        <Footer.Item label="Item" />
        <Footer.Item label="Item" />
        <Footer.Item label="Item" />
    </Footer.Navigation>
    <Footer.Base copyrightHolder="Copyright" copyrightText="All rights reserved">
      <Footer.Item label="Link" />
      <Footer.Item label="Link" />
      <Footer.Item label="Link" />
    </Footer.Base>
</Footer>
</>
`}</code></pre>
    </Playground>
    <h3 {...{
      "id": "navigation-section-variations",
      "style": {
        "position": "relative"
      }
    }}>{`Navigation section variations`}<a parentName="h3" {...{
        "href": "#navigation-section-variations",
        "aria-label": "navigation section variations permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <Playground mdxType="Playground">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`<>
<Footer title="Default" footerAriaLabel="HDS Footer">
  <Footer.Navigation navigationAriaLabel="HDS Footer navigation">
      <Footer.Item label="Item" />
      <Footer.Item label="Item" />
      <Footer.Item label="Item" />
      <Footer.Item label="Item" />
      <Footer.Item label="Item" />
      <Footer.Item label="Item" />
      <Footer.Item label="Item" />
      <Footer.Item label="Item" />
  </Footer.Navigation>
</Footer>
<br />
<Footer title="Minimal" footerAriaLabel="HDS Footer">
  <Footer.Navigation variant="minimal" navigationAriaLabel="HDS Footer navigation">
      <Footer.Item label="Item" />
      <Footer.Item label="Item" />
      <Footer.Item label="Item" />
      <Footer.Item label="Item" />
  </Footer.Navigation>
</Footer>
<br />
<Footer title="Sitemap" footerAriaLabel="HDS Footer">
  <Footer.Navigation variant="sitemap" navigationAriaLabel="HDS Footer navigation">
    {[1, 2, 3, 4].map((key) =>
       <Footer.ItemGroup key={key}>
          <Footer.Item label="Item" />
          <Footer.Item subItem label="Sub item" />
          <Footer.Item subItem label="Sub item" />
          <Footer.Item subItem label="Sub item" />
          <Footer.Item subItem label="Sub item" />
          <Footer.Item subItem label="Sub item" />
          <Footer.Item subItem label="Sub item" />
      </Footer.ItemGroup>
    )}
  </Footer.Navigation>
</Footer>
<br />
<Footer title="Hidden" footerAriaLabel="HDS Footer" />
</>
`}</code></pre>
    </Playground>
    <h3 {...{
      "id": "packages",
      "style": {
        "position": "relative"
      }
    }}>{`Packages`}<a parentName="h3" {...{
        "href": "#packages",
        "aria-label": "packages permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Package`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Included`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Storybook link`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Source link`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`HDS React`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><div style={{
              display: 'flex',
              gap: 'var(--spacing-3-xs)'
            }}><IconCheckCircleFill mdxType="IconCheckCircleFill" />{` Yes `}</div></td>
          <td parentName="tr" {...{
            "align": null
          }}><Link openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="/storybook/react/?path=/story/components-footer--default" mdxType="Link">{`View in Storybook`}</Link></td>
          <td parentName="tr" {...{
            "align": null
          }}><ExternalLink openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="https://github.com/City-of-Helsinki/helsinki-design-system/tree/master/packages/react/src/components/footer" mdxType="ExternalLink">{`View source`}</ExternalLink></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`HDS Core`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><div style={{
              display: 'flex',
              gap: 'var(--spacing-3-xs)'
            }}><IconCrossCircle mdxType="IconCrossCircle" />{` No `}</div></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "properties",
      "style": {
        "position": "relative"
      }
    }}>{`Properties`}<a parentName="h3" {...{
        "href": "#properties",
        "aria-label": "properties permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`The Footer component consists of multiple components that all have their own properties. Refer to the `}<Link openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="/storybook/react/?path=/story/components-footer--default" mdxType="Link">{`React Storybook`}</Link>{` to see properties of all Footer components.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      